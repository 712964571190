/* You can add global styles to this file, and also import other style files */

/* @import '@angular/material/prebuilt-themes/deeppurple-amber.css'; */

html {
  height: 100%;
  background: url(https://storage.googleapis.com/proimagesingapur/backoffice/hogami_bo_background.jpg) no-repeat center center fixed, #eeeef0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
    height: 100%;
    color: #3c3738;
    font-family: LeroyMerlinSans, Helvetica,'Arial';
    font-size: 0.875rem;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
}

.menu {
padding: 10px;
}
.content {
padding: 10px;
}

.vertical-spacer {
    height: 100px;
}
/** Generic styles */
.client_name {
    font-weight: bold;
    font-family: LeroyMerlinSansBold, Helvetica,'Arial';
}

.client_email {
    font-style: italic;
}

.professional_name {
    font-weight: bold;
    font-family: LeroyMerlinSansBold, Helvetica,'Arial';
}

.professional_email {
    font-style: italic;
}

.professional_document {
}

.service_code {
    font-weight: bold;
    font-family: LeroyMerlinSansBold, Helvetica,'Arial';
}
.service_name {
    font-weight: bold;
    font-style: italic;
}

.services_price {
}

.service_tax {
}

.service_date{
}