@use '@angular/material' as mat;

// Be sure that you only ever include this mixin once!
@include mat.core();

// Define your theme with color palettes, typography and density

$mat-theme-primary-palette: map-merge(mat.$cyan-palette, ( 501: #78be20,201: #add585,701: #41a017,601: #3c3738,contrast: (501: #ffffff,201: #ffffff,701: #ffffff,)));
$mat-theme-primary: mat.define-palette(
  $mat-theme-primary-palette,
  $default: 501,
  $lighter: 201,
  $darker: 701,
  $text: 601
);

$mat-theme-accent-palette: map-merge(mat.$teal-palette, ( 501: #78be20,201: #add585,701: #41a017,601: #3c3738,contrast: (501: #ffffff,201: #ffffff,701: #ffffff,)));
$mat-theme-accent: mat.define-palette(
  $mat-theme-accent-palette,
  $default: 501,
  $lighter: 201,
  $darker: 701,
  $text: 601
);

$mat-theme-warn-palette: map-merge(mat.$pink-palette, ( 501: #fc961e,201: #add585,701: #41a017,601: #3c3738,contrast: (501: #ffffff,201: #ffffff,701: #ffffff,)));
$mat-theme-warn: mat.define-palette(
  $mat-theme-warn-palette,
  $default: 501,
  $lighter: 201,
  $darker: 701,
  $text: 601
);

$mat-dark-theme-primary-palette: map-merge(mat.$lime-palette, ( contrast: (200: #030844,A100: rgba(0,0,0, 0.87),A700: rgba(0,0,0, 0.87),)));
$mat-dark-theme-primary: mat.define-palette(
  $mat-dark-theme-primary-palette,
  $default: 200,
  $lighter: A100,
  $darker: A700,
  $text: 700
);

$mat-dark-theme-accent-palette: map-merge(mat.$green-palette, ( contrast: (A200: black,50: black,A400: black,)));
$mat-dark-theme-accent: mat.define-palette(
  $mat-dark-theme-accent-palette,
  $default: A200,
  $lighter: 50,
  $darker: A400,
  $text: A100
);

$mat-dark-theme-warn-palette: map-merge(mat.$pink-palette, ( contrast: (A100: black,100: white,)));
$mat-dark-theme-warn: mat.define-palette(
  $mat-dark-theme-warn-palette,
  $default: A100,
  $lighter: 100,
  $darker: A700,
  $text: 100
);

$mat-density: 0;

$mat-core-theme: mat.define-light-theme((
  color: (
    primary: $mat-theme-primary,
    accent: $mat-theme-accent,
    warn: $mat-theme-warn
  ),
  density: $mat-density
));

$mat-dark-theme: mat.define-dark-theme((
  color: (
    primary: $mat-dark-theme-primary,
    accent: $mat-dark-theme-accent,
    warn: $mat-dark-theme-warn,
  )
));

@include mat.all-component-themes($mat-core-theme);

.dark-theme {
  @include mat.all-component-colors($mat-dark-theme);
}

// Foreground Elements
// Light Theme Text
$dark-text: #3c3738;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

// Background config
// Light bg
$light-background:    #eeeef0;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);
$dark_gray: #3c3738de;
$gray : #887f87;
$green: #b7d9a9;
$blue: #d9edff;
$orange: #E38968;
$lm_green: #78be20;

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#eeeef0, 0.04);
$light-bg-alpha-12:   rgba(#eeeef0, 0.12);

// Handle buttons appropriately, with respect to line-height

strong {
  font-family: LeroyMerlinSansBold, Helvetica,'Arial';
}

.mat-raised-button, .mat-stroked-button, .mat-flat-button {
padding: 0 1.15em;
margin: 0 .65em;
min-width: 3em;
line-height: 36.4px
}

.button-row button {
margin: 10px;
}

.mat-standard-chip {
padding: .5em .85em;
min-height: 2.5em;
}

.material-icons {
font-size: 24px;
font-family: 'Material Icons', 'Material Icons';
.mat-badge-content {
  font-family: 'LeroyMerlinSans';
}
}


.mat-toolbar {
  background: transparent !important;
  color: #fff !important;
  position: sticky;
  position: -webkit-sticky; /* For macOS/iOS Safari */
  top: 0; /* Sets the sticky toolbar to be on top */
  z-index: 1000; /* Ensure that your app's content doesn't overlap the toolbar */
}


.toolbar-logo {
  padding: 0 16px;
  width: 100%;
  align-items: center;
  white-space: nowrap;
  background: $lm_green;
}

.header_pro_env {

}
.header_pre_env{
  background: #9e9e9e;
}


.toolbar-menu {
  padding: 0 16px;
  width: 100%;
  align-items: center;
  white-space: nowrap;
  background: #0000009e;
  height: 40px !important;
  overflow: hidden !important;


  a:link , a:visited, a:hover, a:active {
      text-decoration: none;
  }
  .menu-buttons-row {
      display: flex;
  }
}

html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #3f51b5;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  color: #78be20 !important;
}

.mat-mdc-fab.mat-primary, .mat-mdc-flat-button.mat-primary, .mat-mdc-mini-fab.mat-primary, .mat-mdc-raised-button.mat-primary{
color: $light-text;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
background-color: white;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
width: 100%;
}

.mat-expansion-panel-content {
font: 400 14px / 20px LeroyMerlinSans;
letter-spacing: 0.0179em;
padding-top: 10px;
border-top: 1px solid #d9d9db;
}

.mat-mdc-form-field{
display: flex !important;
margin-right: 10px;
}
.radio-buttons-container {
  padding: 10px 0px;
}

.full-width {
  width: 100%
}
.middle_width {
  width: 60%
}

/* Table columns style */
.example-container {
display: flex;
flex-direction: column;
max-height: 100%;
margin-bottom: 100px;
}

.mat-mdc-table {
  width: 100%;
  bottom: 20px;
  margin-top: 10px;
  overflow: auto;
  min-height: 500px;
  width: 100%;
}

.mat-mdc-header-cell{
  padding: 10px 20px !important;
  text-align: center !important;
  vertical-align: middle;
  background: #72B526;
  color: #fff;
  height:100%;
}

.mat-mdc-cell{
  padding: 20px !important;
  text-align: left !important;
  vertical-align:top;
  font-size: 14px;
}

.mat-mdc-table {
overflow: auto;
max-height: 100%;
}
.container{
  background-color: white;
  border: 1.5px solid black;
  border-radius: 10px;
  text-align: center;
}

tr.mat-mdc-header-row {
  height: 56px;
  padding: 6px;
}

.mat-mdc-paginator{
  background-color: white;
}


.mat-mdc-row, .mat-mdc-header-row {
  width: 100%;
  min-height: auto;
}

.mat-mdc-column-fill{
  width:100%;
}
.mat-column-operationOfferedServices , .mat-column-offeredService, .mat-column-productOffered {
  min-width: 270px;
}

.mat-column-totalCost {
min-width: 100px;
}

.mat-column-totalPaid {
min-width: 100px;
}

.mat-column-clientDetails {
  min-width: 270px;
}

.mat-column-professionalDetails {
  min-width: 270px;
}

.mat-column-operationAddress , .mat-column-address , .mat-column-requestAddress {
min-width: 270px;
}

.mat-column-products {
min-width: 270px;
}

.mat-column-startTime {
min-width: 100px;
}

.mat-column-endTime {
min-width: 100px;
}

.mat-column-id {
  text-align: center !important;
  min-width: 80px;
}

.mat-column-paycomentReference {
    min-width: 200px;
}
.mat-column-paycometConcept {
    min-width: 300px;
}

.mat-column-images {
    min-width: 200px;
}

.mat-column-movements {
    min-width: 300px;
}

.mat-more-container {
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 0 8px;
  flex-wrap: wrap-reverse;
  width: 100%;
}

.float_right {
  float: right;
  }

.mdc-card {
display: flex;
flex-direction: column;
box-sizing: border-box;
}

.login-card {
  width: 320px;
  text-align: center;
  margin: 0;
  position: absolute !important;
  top: 35%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.small-card {
  width: 320px;
  margin: 40px auto;
}

.top_logo{
  width: 160px;
}

.login_logo {
  width: 260px;
}

.footer {
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 40px;
  background-color: #78be20;
  color: #fff;
  margin-top: 100px;
  z-index: 999;
}

.footer-copyright {
  float: right;
  margin-right: 30px;
  padding: 5px;
}


a:link {
color: #000;
text-decoration: none;
}

a:visited {
color: #000;
text-decoration: none;
}

a:hover {
color: #000;
text-decoration: underline;
}

a:active {
color: #000;
text-decoration: underline;
}

.currency {
  text-align: right
}

.underline{
  border-bottom: 1px #3c3738 solid;
}
.upperline{
  border-top: 1px #3c3738 solid;
}

/* HOME */

.iframe_container {
position: relative;
overflow: hidden;
width: 100%;
height: 500px;
overflow-x:hidden;
overflow-y:scroll;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
width: 100%;
height: 100%;
}

/*  CHAT */

.chat_header {
font-size: 20px;
color: $dark_gray;
background-color: transparent;
}
.chat_client_text{
float: left;
}
.chat_pro_text{
float: right;
}

.chat-history {
  padding: 0px;
  overflow-y: scroll;

  .message-data {
    margin-bottom: 15px;
    font-size: 12px;
  }

  .message-data-time {
    color: lighten($gray, 8%);
    padding-left: 6px;
  }
  .message-data-type {
    color: $gray;
    padding-left: 6px;
  }


  .message {
    color: $dark_gray;;
    padding: 5px 10px;
    line-height: 18px;
    font-size: 14px;
    border-radius: 4px;
    width: 96%;
    position: relative;
    margin-top: 10px;

    &:after {
      bottom: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 10px;
      margin-left: -10px;
    }
  }

  .message_cli {
    background: $green;
      &:after {
        border-bottom-color: $green;
        left: 20px;
      }
  }

  .message_pro {
    background: $blue;
    &:after {
      border-bottom-color: $blue;
      right: 20px;
    }
  }

  .mat-row:nth-child(even){
      background-color: #fff !important;
  }

  .mat-row:nth-child(odd){
      background-color: #fff !important;
  }

  .sender_pro {
      padding-right: 40px;
      max-width: 90%;
      float: right;
      margin-bottom: 30px;
      text-align: right;

      .message_pro {
          text-align:left;
      }
  }

  .sender_cli {
      padding-left: 20px;
      max-width: 90%;
      float: left;
      margin-bottom: 10px;
  }

  .chat_picture {
      max-width: 500px;
      max-height: 500px;
      width:90%;
      border: 1px #bab6bc solid;
  }



  .chat_company_warning , .chat_company_advise {

      .title {
        font-size: 16px;
        margin-bottom: 8px;
        color: #78be20;
      }

      .message {
      text-align: center;
      border: 2px solid #78be20;
      margin-bottom: 1rem;
      border-radius: 8px;
      padding: 12px;
      background: #fff;
            &:after {
              border-bottom-color: transparent;
            }
      }
      .message-data-name, .message-data-type {
          display: none;
      }
  }

  .chat_company_caution {

    .title {
      font-size: 16px;
      margin-bottom: 8px;
      color: #78be20;
    }

    .message {
    text-align: left;
    border: 2px solid #c50707;
    margin-bottom: 1rem;
    border-radius: 8px;
    padding: 12px;
    background: #fff;
          &:after {
            border-bottom-color: transparent;
          }
    }
    .message-data-name, .message-data-type {
        display: none;
    }
  }

  .chat_company_advice {

    .title {
      font-size: 16px;
      margin-bottom: 8px;
      color: #78be20;
    }

    .message {
    text-align: left;
    border: 2px solid #78be20;
    margin-bottom: 1rem;
    border-radius: 8px;
    padding: 12px;
    background: #fff;
          &:after {
            border-bottom-color: transparent;
          }
    }
    .message-data-name, .message-data-type {
        display: none;
    }
  }


  .chat_date , .chat_add_service , .chat_add_product, .chat_confirm_work , .chat_address , .chat_done, .chat_budget, .chat_final_budget {

      .title {
        font-size: 16px;
        margin-bottom: 8px;
        color: #78be20;
      }

      .message {
      text-align: center;
      border: 2px solid #eee;
      margin-bottom: 1rem;
      border-radius: 8px;
      padding: 12px;
      background: #eee;
            &:after {
              border-bottom-color: transparent;
            }
      }
      .message-data-name, .message-data-type {
          display: none;
      }
  }

  .chat_paid, .chat_photo_client {

      .title {
        font-size: 16px;
        margin-bottom: 8px;
        color: #78be20;
      }

      .message {
      text-align: center;
      border: 2px solid #eee;
      margin-bottom: 1rem;
      border-radius: 8px;
      padding: 12px;
      background: #fff;
            &:after {
              border-bottom-color: transparent;
            }
      }
      .message-data-name, .message-data-type {
          display: none;
      }
  }

  .chat_cancel {
      .title {
        font-size: 16px;
        margin-bottom: 8px;
        color: #78be20;
      }

      .message {
      border: 2px solid #f5d8d8;
      margin-bottom: 1rem;
      border-radius: 5px;
      padding: 5px;
      background: #f5d8d8;
            &:after {
              border-bottom-color: #f5d8d8;
            }
      }
      .message-data-name, .message-data-type {
      display: none;
      }
  }

  .chat_paid_with_delay {
      .title {
        font-size: 16px;
        margin-bottom: 8px;
        color: #78be20;
      }
      .message {
      text-align: center;
      border: 2px solid #eee;
      margin-bottom: 1rem;
      border-radius: 8px;
      padding: 12px;
      background: #eee;
            &:after {
              border-bottom-color: transparent  ;
            }
      }
      .message-data-name, .message-data-type {
      display: none;
      }
  }

  .chat_text {
      .message {
      }
      .message-data-type {
          display: none;
      }
  }



}

.chat-message {
  padding: 30px;

  .fa-file-o, .fa-file-image-o {
    font-size: 16px;
    color: gray;
    cursor: pointer;

  }

}

.work-image{
.close {
}

.image {
    .img-responsive {
        max-width: 100%;
        height: auto;
        display: block;
    }
}
}

.c-chat-message__msgtype__subtitle2--p {
display: list-item;
list-style: disc;
max-width: -moz-fit-content;
max-width: fit-content;
text-align: left;
margin: 0 20px;
}

.advise {
font-weight: 600;
color: #46a610;
}
